import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import HomePage from './pages/home';
import ProfilePage from './pages/profile';
import ProjectsPage from './pages/projects';
import BlogPage from './pages/blogs';
import ContactPage from './pages/contact';

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/Profile" element={<ProfilePage />} />
      <Route path="/Projects" element={<ProjectsPage />} />
      <Route path="/Blog" element={<BlogPage />} />
      <Route path="/Contact" element={<ContactPage/>} />
    </Routes>
  );
}

export default App;
