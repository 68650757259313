import * as React from 'react';
import PageContainer from '../Components/Layouts/PageContainer';
import UnderConstruction from '../Static/images/underconstruction.png';

const Projects = () => {

    return (
        <PageContainer title="Projects">
            <div className='container'>
                <div className="section-heading">
                    <h2>Projects</h2>
                </div>
                <img src={UnderConstruction} alt="Under Construction" style={{ width: '50%', height: '50%', textAlign : 'center' }} />
            </div>
        </PageContainer>
    );
};
export default Projects;
