import * as React from 'react';
import PageContainer from '../Components/Layouts/PageContainer';
import UnderConstruction from '../Static/images/underconstruction.png';

const Contact = () => {

    return (
        <PageContainer title="Contact">
            <div className='container'>
                <div className="section-heading">
                    <h2>Contact Me</h2>
                    <p>For now contact via mail : prabindrasapkota89@gmail.com <a className='btn' href="mailto:prabindrasapkota89@gmail.com">Send email</a></p>
                </div>
                <img src={UnderConstruction} alt="Under Construction" style={{ width: '50%', height: '50%', textAlign : 'center'  }} />
            </div>
        </PageContainer>
    );
};
export default Contact;
