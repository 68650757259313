import * as React from 'react';
import Navigation from '../Partials/Navigation';


const PageContainer: React.FunctionComponent<any> = ({
    children,
    title = 'Page',
}) => {
    const titleView = title + " - Prabindra Sapkota";

    return (
        <>

            <title>{titleView}</title>
            <div id="page-wraper">
                <Navigation />
                <section className='section'>
                    {children}
                </section>
            </div>
        </>
    );
};

export default PageContainer;
