
import * as React from 'react';
import { Link } from 'react-router-dom';
import logoprofile from '../../Static/images/logo-image.jpg'
// import logo from '../../Static/images/logos/logo.jpeg'

const Navigation = () => {
    return (
        <div className='responsive-nav'>
            <div className="responsive-nav">
                <i className="fa fa-bars" id="menu-toggle"></i>
                <div id="menu" className="menu">
                    <i className="fa fa-times" id="menu-close"></i>
                    <div className="container">
                        <div className="image">
                            <a href="/"><img src={logoprofile} alt="Prabindra Sapkota" /></a>
                            {/* <img src={profile} alt="Prabindra" height="150" width="150" className="rounded m-2" /> */}
                        </div>
                        <div className="author-content">
                            <h4>Prabindra Sapkota</h4>
                            <span> Game Developer</span>
                        </div>
                        <div className="social-network">
                            <ul className="soial-icons">
                                <li>
                                    <a href="https://www.linkedin.com/in/prabindrasapkota/" target="_blank" rel="noreferrer"><i className="fa fa-linkedin"></i></a>
                                </li>
                                <li>
                                    <a href="https://github.com/Parzival-101" target="_blank" rel="noreferrer"><i className="fa fa-github"></i></a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/Prabindra101" target="_blank" rel="noreferrer"><i className="fa fa-twitter"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.facebook.com/PrabindraSapkota101" target="_blank" rel="noreferrer"><i className="fa fa-facebook"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/prabindrasapkota/" target="_blank" rel="noreferrer"><i className="fa fa-instagram"></i></a>
                                </li>
                                <li>
                                    <a  href="mailto:prabindrasapkota89@gmail.com" target="_blank" rel="noreferrer"><i className="fa fa-google"></i></a>
                                </li>
                            </ul>
                            <hr />
                        </div>
                        <nav className="main-nav" role="navigation">
                            <ul className="main-menu">
                                <li>
                                    <Link to="/" className='nav-link'>Home</Link>
                                </li>
                                <li>
                                    <Link to="/Projects" className='nav-link'>Projects</Link>
                                </li>
                                <li>
                                    <Link to="/Blog" className='nav-link'>Blog</Link>
                                </li>
                                <li>
                                    <Link to="/Profile" className='nav-link'>Skills</Link>
                                </li>
                                {/* <li>
                                    <Link to="/Contact" className='nav-link'>Contact</Link>
                                </li> */}
                            </ul>
                        </nav>

                    </div>
                </div>
            </div>
        </div>
    );
};
export default Navigation;