import * as React from 'react';
import PageContainer from '../Components/Layouts/PageContainer';
import homebg from '../Static/images/homebg.jpg';

const Home = () => {

    const galleryImages = [
        {
            title: 'HLC Team',
            source: 'gallery/team-hlc.jpg',
            info: 'During Office Tour @bandipur',
        },
        {
            title:"TX Photowalk",
            source:'gallery/txPhotowalk.jpg',
            info : 'Photowalk at @Pashupatinath'
        },
        {
            title: 'KYC Team',
            source: 'gallery/kyc-group-photo.jpg',
            info: 'During KYC-2020 Main event day',
        },
        {
            title : 'TechTrix Team',
            source:'gallery/txGroup2019.jpg',
            info:'Team of Tech-Trix 2019'
        },
        {
            title: 'Farewell Dance',
            source: 'gallery/nsc-farewell-dance.jpg',
            info: 'Dance @UniSummit Farewell program 2020',
        },
        {
            title :'HExCode Jatra',
            source :'gallery/hex.jpeg',
            info : 'Participated at Hackathon @Himalayan Engineering College'
        },
        {
            title: 'TechTrix-2019',
            source: 'gallery/tx-hackathon.jpg',
            info: '@Hackathon event on Tech-Trix-2019',
        },
        {
            title: 'Everest Hack',
            source: 'gallery/team-eh.jpg',
            info: 'Group-Photo :Team of EH',
        },
        {
            title: 'Fintech Summit',
            source: 'gallery/hyatt.png',
            info: '@Hyatt Regency',
        },
        {
            title : 'Expo',
            source: 'gallery/canInfoTech.jpg',
            info : 'Exibitor @CAN Info-Tech'
        },
        {
            title : 'Flash Mob',
            source : 'gallery/flashMob.jpg',
            info : '@Patan Durbar Square'
        },
        {
            title : '@Softwarica',
            source : 'gallery/softorica.jpg',
            info : 'During Softworica Open day and Expo'
        },
        {
            title: 'C# Meetup Chapter-1',
            source: 'gallery/Csharp-meetup.jpg',
            info: 'Meetup of C# corner @Neosphere',
        },
        {
            title : 'Talk Show',
            source :'gallery/preEventTX.jpg',
            info : '@Pre event of TX-2018'
        },
        {
            title: 'Code Build & Solve',
            source: 'gallery/event-build-ktm.jpg',
            info: '@Chiya Paan',
        },
    ]
    return (
        <PageContainer title="Home">
            <div className='container'>
                <div className='top-header'>
                    <img src={homebg} alt="Home Background"></img>
                    <p>.NET Developer at GrayCode Technology I Event Organizer at TechTrix Nepal</p>
                </div>
                <div className="section-heading">
                    <h2> About Me</h2>
                    <span>

                    </span>
                    <p> Developer with a demonstrated history of working in the computer software industry.
                        Skilled in C#, ASP.NET, ReactJs ,SQL and  Unity3D.
                        Strong engineering professional with a Bachelor of Science - BSc.CSIT focused
                        in CSIT from The New Summit College. Actively participating in tech-events.</p>
                </div>
                <div className="row">
                   
                            {galleryImages.map((item: any) => (
                                <div className="isotope-item">
                                    <figure className="snip1321">
                                        <img src={item.source} alt ={item.title} />
                                        <figcaption>
                                            <a
                                                href='/'
                                                data-lightbox="image-1"
                                                data-title={item.title}><i className="fa fa-search"></i></a>
                                            <h4>{item.title}</h4>
                                            <span>{item.info}</span>
                                        </figcaption>
                                    </figure>
                                </div>
                            ))}
                </div>
            </div>
        </PageContainer>
    );
};
export default Home;