import * as React from 'react';
import PageContainer from '../Components/Layouts/PageContainer';
import UnderConstruction from '../Static/images/underconstruction.png';

const Blogs = () => {
    return (
        <PageContainer title="Blogs">
            <div className='container'>
                <div className="section-heading">
                    <h2>My Blogs</h2>
                    <p>These are some of my blogs</p>
                </div>
                <img src={UnderConstruction} alt="Under Construction" style={{ width: '50%', height: '50%', textAlign : 'center' }} />
            </div>
        </PageContainer>
    );
};
export default Blogs;